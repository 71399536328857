import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: '',
  timeout: 50000
})

instance.defaults.transformRequest = (data) => qs.stringify(data)

instance.interceptors.request.use(
  (config) => {
    setHeader(config)
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    let { response } = error

    if (response) {
      switch (response.status) {
        case 401:
          break
        case 403:
          break
        case 404:
          break
      }
    } else {
      if (!window.navigator.onLine) {
        return
      }

      return Promise.reject(error)
    }
  }
)

function setHeader(config) {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  // config.headers["xx-user-token"] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ4eC11c2VyLWlkIjoiNjEyMTYiLCJpc3MiOiJzaG91Z3VhIiwiZXhwIjoxNzI0MjQyNzY1LCJpYXQiOjE3MjE2NTA3NjV9.dcYbgBf7p5p60roiyFVJ61pOIhfqLdA88bQuBe2-Puv6WO3kXvksSo3PHRdEp32Ze6_hDpbOovV1DYWxqb0Z9Q.463mPiw';
  return
}

export default instance
